
<template>

    <div class="foo box">

        <b-tabs size="is-small"
                vertical
                :animated="false"
                multiline
                >
            <b-tab-item label="How it helps" icon="auto_awesome">
                <!-- <h1 class="title is-4">Putting you in control.</h1> -->
                <!-- <h2 class="is-size-4">Better results with <span class="has-text-weight-bold"></span>integrated metallurgy<span class="has-text-grey-light"></span><span class="has-text-weight-bold">.</span></h2> -->
                <h2 class="is-size-4">Team +<span class="has-text-weight-bold"></span> Tech +<span class="has-text-grey-light"></span><span class="has-text-weight-bold"> Results.</span></h2>
                <div>Boost productivity, improve performance &amp; unlock value.</div>

                <div class="zoom-container" style="max-height: 175px;">
                    <div class="item">
                        <img src="/img/feature-mining.jpg" style="max-width: 300px;">
                    </div>
                </div>

                <!-- <div> Delivering faster &amp; better performance.</div> -->
                <!-- <div>Solid Tech | Super Teams | Best Practice</div> -->
                <!-- <p>A smart &amp; simple platform for digital metallurgy.</p> -->
            </b-tab-item>

            <b-tab-item label="Key Features" icon="batch_prediction">

                <div class="badge orange">
                  <div class="circle"><c-icon icon="playlist_add_check"/></div>
                  <div class="ribbon">Capture</div>
                </div>

                <div class="badge green">
                  <div class="circle"><c-icon icon="change_history"/></div>
                  <div class="ribbon">Balance</div>
                </div>

                <div class="badge blue">
                  <div class="circle"><c-icon icon="history"/></div>
                  <div class="ribbon">Reconcile</div>
                </div>

                <div class="badge pink">
                  <div class="circle"><c-icon icon="equalizer"/></div>
                  <div class="ribbon">Report</div>
                </div>

                <div class="badge yellow">
                  <div class="circle"><c-icon icon="badge"/></div>
                  <div class="ribbon">Approve</div>
                </div>

                <div class="badge gold">
                  <div class="circle"><c-icon icon="auto_awesome"/></div>
                  <div class="ribbon">Connect</div>
                </div>

            </b-tab-item>

            <b-tab-item label="Integrations" icon="open_with">
                <h1 class="title is-4">An integrated &amp; connected system.</h1>
                <h2 class="subtitle is-4 has-text-grey-light">Industry leading integration.</h2>

                <div class="has-text-left">
                <ul>
                    <li><b>Office</b> - Microsoft Excel &amp; PowerBI</li>
                    <li><b>Historian</b> - OSIsoft PI, Rockwell, Emerson, Honeywell</li>
                    <li><b>LIMS</b> - Single, Batch &amp; Composite Assays</li>
                    <li><b>ERP</b> - SAP, Pronto, Oracle, MS SQL, IBM Informix</li>
                </ul>
                </div>

                <br>

                <b-carousel-list v-model="partnerId" :data="partners" :items-to-show="3" :arrow="false" repeat icon-prev="chevron_left" icon-next="chevron_right">
                    <template #item="list">
                        <div class="box partner-logo">
                            <img :src="list.image">
                        </div>
                    </template>
                </b-carousel-list>

            </b-tab-item>

            <b-tab-item label="Clients" icon="auto_stories">
                <h1 class="title is-4">Working with our clients.</h1>
                <h2 class="subtitle is-4 has-text-grey-light">Delivering better digital metallurgy.</h2>

                <div class="zoom-container" style="max-height: 150px;">
                    <div class="item">
                        <img src="/img/banner-team.jpg">
                    </div>
                </div>

                <p>
                    <!-- A solid platform for next-generation digital metallurgy. <br/> -->
                    Solid Technology | Industry Experts |  Easy Onboarding
                </p>
            </b-tab-item>

            <b-tab-item label="Get started!" icon="connect_without_contact">
                <h1 class="title is-4">Start your digital journey.</h1>
                <h2 class="subtitle is-5 has-text-grey-light" style="margin-bottom: 0;">We provide a <span class="has-text-white">clear path </span> to <span class="has-text-white"> light the way.</span> </h2>
                <span> Smart Technology | Industry Experts |  Easy Onboarding </span>

                <div class="zoom-container" style="max-height: 170px;">
                    <div class="item">
                        <img src="/img/feature-perth.jpg">
                    </div>
                </div>

                <p>
                    <!-- A solid solution for next-generation digital metallurgy. <br/> -->
                    <button class="button is-primary">Let's get started!</button>
                </p>
            </b-tab-item>

        </b-tabs>

    </div>
</template>

<style >

.foo {
    background-color: rgba(0,0,0,0.5);
}

.b-tabs {
    background-color: #333;
    color: white;
    height: 320px;
}

.b-tabs > nav > ul > li {
    flex-grow: 1;
    padding: 1em;
    border: none;
}

.b-tabs.is-vertical > .tabs li a {
    height: 100%;
    border: none;
}

.b-tabs.is-vertical > .tabs li.is-active a {
    background-color: #444 !important;
    color: #f0f0f0 !important;
    border-radius: 3px;
}

section.tab-content {
    max-width: 75%;
}

.tab-item {
    max-height: 280px;
}

.partners {
    display: flex;
    flex-direction: row;
}

.partner-logo {
    max-width: 150px;
    max-height: 75px;
    margin: 0px 5px;
    background-color: #f0f0f0;
}

.partner-logo img {
    padding-top: 7px;
}

</style>

<script>

export default {
  name: 'home',
  data () {
    return {
      partnerId: 0,
      partners: [
        {
          title: 'OSIsoft',
          image: '/img/logo-osisoft.png'
        },
        // {
        //   title: 'Esri',
        //   image: '/img/logo-esri.png'
        // },
        {
          title: 'Microsoft',
          image: '/img/logo-microsoft.png'
        },
        {
          title: 'Unified',
          image: '/img/logo-unified.png'
        }
      ]
    }
  }
}
</script>
