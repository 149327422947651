<template>
  <nav class="navbar navbar-primary is-fixed-top" role="navigation" aria-label="main navigation">
    <div id="site-header" class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <div class="navbar-wordmark">
          <!-- <img src="/logo.png" style="filter: grayscale(100%); opacity: 0.9; padding: 15px 30px 10px 5px; height: 60px;"> -->
            <h1 class="is-size-2"><span class="has-text-weight-light">Met</span><span class="has-text-weight-bold has-text-grey logo-x-gradient">X</span><sup style="font-size: 0.25em;">&copy;</sup></h1>
          </div>
        </a>

        <span id="navbar-brand-tagline">
          <span class="has-text-weight-light">Integrated</span>
          <span class="has-text-weight-normal">Digital</span>
          <span class="has-text-weight-bold">Metallurgy</span>
        </span>

        <div class="navbar-item">
          <p class="ml-2 has-text-dark">by</p>
        </div>

        <img class="navbar-item" src="/logo-convergio-3-flat.svg" width="150px" />

        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMain"
          @click="toggleActive()"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarMain"
        class="navbar-menu"
        :class="{'navbar-secondary-active' : $route.meta.secondary, 'is-active': is_active}"
      >
        <div class="navbar-end">
          <router-link
            class="navbar-item"
            v-for="navItem in navItems"
            :key="navItem.id"
            :to="navItem.to"
          >{{navItem.title}}</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'SiteHeader',
  props: {
    secondary_active: Boolean
  },
  data: function () {
    return {
      is_active: false,
      navItems: [
        { id: 'home', to: '/', title: 'Home' },
        { id: 'gold', to: '/about', title: 'About' },
        { id: 'iron', to: '/partners', title: 'Partners' },
        // { id: "case-studies", to: "/case-studies", title: "Case Studies" },
        // {
        //   id: 'industries',
        //   to: '/industries/mining',
        //   title: 'Industries'
        // },
        { id: 'contact', to: '/contact', title: 'Contact' }
      ]
    }
  },
  methods: {
    toggleActive: function () {
      this.is_active = !this.is_active
    }
  }
}

</script>

<style>

.navbar-wordmark {
  color: #444;
  border: 1px solid silver;
  border-radius: 3px;
  padding: 0px 10px;
}

.logo-x-gradient {
  background-image: linear-gradient(45deg,  #444, #777, #444);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.route-industries-mining .logo-x-gradient {
  background-image: linear-gradient(45deg,  #fff200,#c9bf00, #b17900);

}

@media screen and (max-width: 700px) {
  #navbar-brand-tagline {
    display: none !important;
  }
}
</style>
