<template>
  <div class="site-footer">
    <div class="site-footer-details has-background-grey-dark has-text-white">
      <div class="container">
        <div class="columns">
          <div class="column is-hidden-mobile">
            <h2>
              <strong class="has-text-white">Better.Together.</strong>
            </h2>

            <div>Our philosophy is simple.</div>
            <div>We bring together the best people, processes &amp; technology to drive business forward.</div>
            <!-- <div>We see IT/OT convergence as not only delivering safer &amp; more profitable business - we see it as the future of business.</div> -->
            <!-- <div>With industry leading solutions we deliver.</div> -->
            <div>For People. For Profit. For Planet.</div>

            <br>

            <router-link to="/about" class="button is-transparent is-feature">
              <strong>Find out more</strong>
            </router-link>

          </div>

          <div class="column has-text-right">
            <strong class="has-text-white">Convergio</strong>
            <br>1020 Wellington Street
            <br>West Perth, Western Australia
            <br>Call (+61) 1300 864 343
            <br>

            <br>

            <router-link class="button is-primary" to="/contact">
              Get in touch
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer site-footer-notices has-background-grey-lighter has-text-grey-dark has-text-small">
      <div class="container">
        <div class="columns">
          <div class="column has-text-right">Copyright &copy; Convergio 2019.<br> All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style type="text/css">
.site-footer > div {
  padding: 1em;
}

.site-footer-details .columns {
  padding: 90px 0px;
}

</style>
