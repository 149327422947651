import Vue from 'vue'
import Buefy from 'buefy'

import CIcon from './CIcon.vue'

Vue.component('c-icon', CIcon)

Vue.use(Buefy, {
  defaultIconComponent: CIcon,
  defaultIconPack: 'material-icons'
})

//   customIconPacks: {
//     csvg: {
//       sizes: {
//         default: '1x',
//         'is-small': '1x',
//         'is-medium': '2x',
//         'is-large': '3x'
//       }
//     }
//   }
// })

// import './icons'

// import VueSVGIcon from 'vue-svgicon'

// Vue.use(VueSVGIcon)
// Vue.component('c-icon', CIcon)

// Vue.use(Buefy, {
//   defaultIconComponent: CIcon,
//   defaultIconPack: 'csvg',
//   customIconPacks: {
//     csvg: {
//       sizes: {
//         default: '1x',
//         'is-small': '1x',
//         'is-medium': '2x',
//         'is-large': '3x'
//       }
//     }
//   }
// })
