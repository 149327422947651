<template>
  <i class="material-icons">
    {{ iconRectified }}
  </i>
</template>

<script>

export default {
  name: 'CIcon',
  props: {
    icon: [String, Array],
    size: String
  },
  computed: {

    calcSize () {
      if (this.size === '1x') {
        return '18'
      } else if (this.size === '2x') {
        return '24'
      } else if (this.size === '3x') {
        return '48'
      }

      return '18'
    },

    iconRectified () {
      // 1.1 If we are passed an array, we only require the icon
      var value = this.icon

      if (Array.isArray(value)) {
        value = value[value.length - 1]
      }

      return value
    }

  }
}
</script>
