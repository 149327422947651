import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue')
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('./views/Partners.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue')
    },
    {
      path: '/case-studies',
      name: 'case-studies',
      component: () => import('./views/Case-Studies.vue')
    },
    {
      meta: { secondary: true },
      path: '/industries',
      name: 'industries',
      component: () => import('./views/Industries.vue')
    },
    {
      meta: { secondary: true },
      path: '/industries/mining',
      name: 'mining',
      component: () => import('./views/Industries-Mining.vue')
    },
    {
      meta: { secondary: true },
      path: '/industries/energy',
      name: 'energy',
      component: () => import('./views/Industries-Energy.vue')
    },
    {
      meta: { secondary: true },
      path: '/industries/manufacturing',
      name: 'manufacturing',
      component: () => import('./views/Industries-Manufacturing.vue')
    },
    {
      meta: { secondary: true },
      path: '/industries/utilities',
      name: 'utilities',
      component: () => import('./views/Industries-Utilities.vue')
    }
  ]
})
