<template>

    <b-carousel-list v-model="partnerId" :data="clients" :autoplay="true" :interval="300" :items-to-show="7" :arrow="false" repeat icon-prev="chevron_left" icon-next="chevron_right" animated="fade">
        <template #item="list">
            <div class="client-logo">
                <img :src="'/img/clients/' + list.prefix + '.png'">
            </div>
        </template>
    </b-carousel-list>

</template>

<style>

.client-logo img {
  max-height: 75px;
  filter: grayscale();
  padding: 0.5em;
}

.client-logo img:hover {
    filter: none;
    cursor: pointer;
}

.carousel-slides {
    align-items: center;
    justify-content: center;
}

</style>

<script>

export default {
  name: 'home',
  data () {
    return {
      partnerId: 0,
      clients: [
        { prefix: 'aurelia-metals' },
        { prefix: 'glencore' },
        { prefix: 'northern-star' },
        // { prefix: 'newmont' },
        { prefix: 'northern-minerals' },
        { prefix: 'rio-tinto' },
        { prefix: 'roy-hill' }
      ]
    }
  }
}
</script>
