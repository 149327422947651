<template>
  <div>
    <section id="hero-home" class="hero has-text-centered is-dark hero-emphasis">
      <div id="hero-image"></div>
      <div class="hero-body" id="main-hero-body">
        <div class="container">
          <div class="columns">

          <div class="column has-text-left feature-text">
            <h1 class="title has-text-white is-size-1">Digital Metallurgy<br>
             <span class="has-text-grey-light">Done Right</span>
            </h1>
            <h2 class="subtitle is-size-3 has-text-white">
              Smart | Simple | Scalable
            </h2>
            <p>
              <!-- Many met systems have come and gone. Ours is engineered to endure.
            <br>
            <br> -->
              Our integrated digital metallurgy platform improves your met operations through state-of-the-art balancing, forecasting, reconciliation, reporting, efficiency &amp; performance analysis.
            </p>

            <br>
            <p>Let's have a chat and see how we can help your operation perform better.</p>
            <p>We're always up for a challenge, and we're motivated by real results.</p>

            <br/>
            <div class="is-flex is-align-items-center">
              <router-link to="/contact" class="button is-primary"> Contact us today</router-link>
            </div>

          </div>

          <div class="column is-offset-1 is-6 features-box">
            <HomeFeatures />
          </div>

        </div>
        </div>
      </div>

    </section>

    <section class="hero is-light has-text-centered">
      <div class="hero-body">
          <div class="container">
            <HomeClients />
          </div>
        </div>
    </section>

    <section class="hero is-bold is-light has-text-centered">
      <div class="hero-body">
        <div class="container">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div class="box">
                <!-- <div class="circle-icon">
                  <i class="far fa-lightbulb"></i>
                </div> -->
                <div class="badge yellow">
                  <div class="circle"><i class='fa fa-bolt'></i></div>
                  <div class="ribbon">Capture</div>
                </div>

                <h3 class="is-size-5">Integrated Data Capture</h3>
                <p class="has-text-left">
                  Securely capture your metallurgical accounting data to be analysed and validated by external systems and stakeholders.
                </p>
              </div>
            </div>

            <div class="tile is-parent">
              <div class="box">
                <div class="badge green">
                  <div class="circle"><i class='fa fa-signal'></i></div>
                  <div class="ribbon">Balance</div>
                </div>

                <!-- <div class="circle-icon">
                  <i class="fa fa-signal"></i>
                </div> -->
                <h3 class="is-size-5">Precise and Robust Balancing</h3>
                <p class="has-text-left">
                  Reduce error and boost confidence with robust and performant metallurgical balancing.
                </p>
              </div>
            </div>

            <div class="tile is-parent">
              <div class="box">
                <!-- <div class="circle-icon">
                  <i class="fas fa-compress-arrows-alt"></i>
                </div> -->
                <div class="badge blue">
                  <div class="circle"><i class='fa fa-compress-arrows-alt'></i></div>
                  <div class="ribbon">Reconcile</div>
                </div>

                <h3 class="is-size-5">Streamlined Reconciliation</h3>
                <p class="has-text-left">
                  Build confidence into your metallurgical processes and data with a single, reconciled source of truth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero is-medium is-bold is-light">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Scalable and Flexible</h1>
          <h2
            class="subtitle"
          >Get better performance out of your operations with a system that adapts to your existing processes and technologies.</h2>
        </div>
      </div>
    </section>

    <section class="hero is-medium is-bold is-dark has-text-right">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Data integrity and traceability</h1>
          <h2 class="subtitle">Uplift your current operation to industry best practices with a secure digital metallurgy platform to protect your data assets.</h2>
        </div>
      </div>
    </section>

    <section class="hero is-medium is-bold is-light">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Phased, Incremental Adoption</h1>
          <h2
            class="subtitle"
          >Manage your risk and spread costs over time as we help you every step along the way to adopt the new system.</h2>
        </div>
      </div>
    </section>

  </div>
</template>

<style type="text/css">

/* #hero-home .feature-text {
  background-image: linear-gradient(110deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 45%, rgba(0,0,0,0) 46%, rgba(0,0,0,0) 0%);
} */

/* #hero-home { */
  /* background-image: linear-gradient(110deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 45%, rgba(0,0,0,0) 46%, rgba(0,0,0,0) 0%), url("/img/grinding-mills.jpg"); */
  /* background-blend-mode: overlay; */
  /* background: url("/img/grinding-mills.jpg"); */
/* } */

#hero-image {
  background: url("/img/grinding-mills.jpg");
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 42% 100%);
  width: 100%;
  height: 100%;
  /* height: calc(100vh - 72px); */
  position: absolute;
  height: 456px;
  background-position-x: 550px;
}

@media screen and (max-width: 1425px) {
  #hero-image {
    display: none;
  }
  .features-box {
    display: none;
  }
  #main-hero-body {
    background: linear-gradient( rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 0.67) ), url("/img/grinding-mills.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

/* .feature-image {
  background: linear-gradient(110deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 45%, rgba(0,0,0,0) 46%, rgba(0,0,0,0) 0%);
} */

/* .feature-image img {
  position: absolute;
  width: 100%;
}

.feature-text {
  position: relative;
  display: inline-block;
  padding: 1em 5em 1em 1em;
  overflow: hidden;
  color: #fff;
  background: #888;
}

.feature-text:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-45deg);
  -ms-transform: skew(-45deg);
  transform: skew(-45deg);
  z-index: -1;
} */

</style>

<script>
import HomeFeatures from './HomeFeatures.vue'
import HomeClients from './HomeClients.vue'

export default {
  name: 'home',
  components: {
    HomeFeatures,
    HomeClients
  }
}
</script>
