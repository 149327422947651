<template>
  <div id="app" :class="'route' + $router.currentRoute.path.replaceAll('/','-')">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css">

    <site-header :secondary_active="secondary_active"/>

    <div class="site-content">
      <router-view/>
    </div>

    <site-footer/>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'app',
  components: {
    SiteHeader,
    SiteFooter
  },
  data: function () {
    return {
      secondary_active: false
    }
  }
}
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import "@/styles/hexagon.scss";

@import '~material-icons-font/sass/variables';
@import '~material-icons-font/sass/mixins';

$MaterialIcons_FontPath: "~material-icons-font/fonts";
@import '~material-icons-font/sass/main';
@import '~material-icons-font/sass/Regular';

// Set your colors
$highlight: #007eb6;
$highlight-hover: #009cdf;

$primary: $highlight;
$primary-invert: findColorInvert($primary);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Navbar
$navbar-item-hover-background-color: $primary;
$navbar-item-hover-color: $white;
$navbar-item-active-background-color: $primary;
$navbar-item-active-color: $white;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Custom styles

.site-content {
  margin-top: 55px;
}

.navbar.navbar-primary {
  z-index: 35;
}

.navbar.is-fixed-top {
  border-bottom: 3px solid $highlight;
}

#navbar-brand-tagline {
  display: flex;
  // margin-left: 15px;
  // font-size: 0.75rem;
  align-items: center;
  // font-style: italic;
}

#navbar-brand-tagline > span {
  margin-left: 0.25em;
}

#navbar-brand-tagline .together {
  font-weight: 200;
}

#navbar-brand-tagline .trade {
  font-style: normal;
}

@include from($tablet) {
  .navbar-item img {
    max-height: 95%;
  }

  .navbar-primary a.navbar-item.is-active,
  .navbar-primary .navbar-link.is-active {
    border-top: 3px solid white;
  }

  .navbar-secondary a.navbar-item.is-active,
  .navbar-secondary .navbar-link.is-active {
    border-bottom: 3px solid white;
  }

  .navbar-item {
    background-color: $highlight;
  }

  a.navbar-item:hover {
    background-color: $highlight-hover;
  }

  .navbar-item {
    color: white;
  }

  .navbar-brand > .navbar-item {
    padding: 3px;
    background-color: white;
  }

  .navbar-end::before {
    display: block;
    content: "";
    border-top: 67px solid transparent;
    border-right: 22px solid $highlight;
    border-left: 0px;
    margin-left: -22px;
  }

  .navbar-end::after {
    display: block;
    content: "";
    border-top: 67px solid $highlight;
    border-right: 22px solid transparent;
    border-left: 0px;
    right: -22px;
  }

  .navbar-industries .navbar-menu {
    padding-top: 15px;
    padding-right: 100px;
  }

  /* .navbar-menu.navbar-secondary-active .navbar-item {
    background-color: grey !important;
  }

  .navbar-menu.navbar-secondary-active .navbar-end::before {
    border-right-color: grey;
  }
  .navbar-menu.navbar-secondary-active .navbar-end::after {
    border-top-color: grey;
  } */

  /* a.navbar-item.navbar-secondary-active,
  .navbar-link.is-active {
    height: 98%;
  } */

  /* .navbar-item img {
    max-height: 100% !important;
  } */

  form {
    min-width: 500px;
  }
}

a.button.is-transparent {
  background: transparent;
  border: 1px solid white;
  color: white;
}

a.button.is-transparent:hover {
  border: 1px solid white;
  color: white;
}

a.button.is-feature {
  transition: all 0.2s ease;
  transition-property: transform, opacity, color, border-color, background-color;
}

a.button.is-feature:hover {
  border: 1px solid white;
  background: white;
  color: $dark;
}

.hero-emphasis {
  border-bottom: 3px solid $highlight;
}

.hero-feature {
  max-width: 400px;
  max-height: 225px;
  margin-right: 40px;
}

.zoom-container {
  display: inline-block;
  position: relative;
  max-width: 400px;
  overflow: hidden;
}

.zoom-container .item {
  display: block;
}

.zoom-container .item img {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.zoom-container:hover .item img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.box h3 {
  line-height: 2.5em;
}

.circle-icon i {
  font-size: 24px;
}

.circle-icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border: solid 1px #fff;
  border-radius: 40px;
  border-color: $highlight;
  color: $highlight;
  text-align: center;
}

@font-face {
  font-family: 'Rustico';
  src: url('/fonts/rustico-regular-4c9046253d.woff2') format('woff2');
}

.hero__gram {
    color: #ffd300;
    display: inline-flex;
    font-family: Rustico;
    font-size: 1.3em;
    font-style: normal;
    font-weight: 400;
    transform: rotate(-4deg);
}

</style>
